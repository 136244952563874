import { Route, Routes, Outlet } from 'react-router-dom';
import Coaches from './pages/Coaches/Coaches';
import Home from './pages/Home/Home';
import NotFound from './pages/NotFound/NotFound';
import RegistrationForm from './pages/RegistrationForm/RegistrationForm';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import Timetable from './pages/Timetable/Timetable';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/coaches" element={<Coaches />} />
      <Route path="/timetable" element={<Timetable />} />
      <Route path="/registration-form" element={<Outlet />}>
        <Route index element={<RegistrationForm />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
