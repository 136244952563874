import { FC, ReactElement } from 'react';
import Banner from '../../components/Banners/_Banner/Banner';
import TAC from '../../components/TAC/TAC';
import { Box } from '@mui/material';

const TermsAndConditions: FC = (): ReactElement => {
  return (
    <>
      <Banner title="TERMS AND CONDITIONS" />
      <Box paddingTop={3} />
      <TAC />
    </>
  );
};

export default TermsAndConditions;
