import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';

const TAC: FC = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Terms and Conditions & Waiver Form
      </Typography>

      <Box marginY={4}>
        <Typography variant="h5" gutterBottom>
          The Membership
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="1. Your membership is a personal membership to you and may not be used by another person or transferred over." />
          </ListItem>
        </List>
      </Box>

      <Box marginY={4}>
        <Typography variant="h5" gutterBottom>
          Fees
        </Typography>
        <List>
          {[
            '1. The member must pay the monthly fee which is determined by the membership package chosen.',
            '2. Payments will be taken on a monthly basis.',
            '3. Payments are made in the first week of each new month.',
            '4. Monthly fees are payable even if you chose not to use the academy.',
            '5. All students are required to pay our yearly club registration fee: £20 pounds for children and £25 for adults. This is an annual fee and is paid yearly.',
            '6. I understand the club maintains a no-fee-no-class policy and it has the right to refuse a session and/or withdraw students from the class if payments are not made.',
          ].map((text, index) => (
            <ListItem key={index}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box marginY={4}>
        <Typography variant="h5" gutterBottom>
          Cancelling your membership
        </Typography>
        <List>
          {[
            '1. Memberships cannot be cancelled prior to the agreed contract length that you have signed up for and the last payment has been made. A contract may be cancelled once the final payment has been made.',
            '2. If you do not plan to attend for a period of time you need to send the club a message via Whatsapp.',
            '3. You are required to inform the club of your intentions before the new month begins and no lessons should be attended or you will be charged the full membership fee for the month concerned.',
          ].map((text, index) => (
            <ListItem key={index}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box marginY={4}>
        <Typography variant="h5" gutterBottom>
          The Academy
        </Typography>
        <List>
          {[
            '1. You are entitled to participate in the classes available for your category of membership. We can refuse you access to the Academy if we consider your health may be adversely affected by participating in the classes.',
            '2. Our centre may open/close earlier during public holiday periods. Facilities may also close for occasional special events and seminars. Notice of these events will be broadcast on our social media accounts in order to inform you in advance of any changes. No refunds will be available for these periods. Your membership does not give you priority over other users or guarantee the availability of facilities.',
            '3. Our management team reserves the right to change the activity programme. Prior notice will be given in the centre relating to activity cancellations or the introduction of new sessions.',
            '4. Students are required to purchase a club Gi from the club in order to benefit from the lessons as well as wear the club logo on all Gis.',
            '5. Students are required to wear a club Gi after a month of training.',
            '6. All students are required to wear a rash guard under their Gi.',
            '7. The club reserves the right to refuse entry to any students or viewers in the best interest of the club.',
            '8. For publicity purposes, the club may share photos/videos of classes on its various social media platforms.',
            '9. Photos and recordings of the training programme is not allowed by students or parents.',
            '10. I understand that the classes taken is a contact sport and that there is a possibility that injuries may occur.',
            '11. I consider myself/child to be medically fit and healthy to train in this martial art.',
            '12. I will update and inform the club coach of any injuries or medical conditions prior to the class.',
          ].map((text, index) => (
            <ListItem key={index}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box marginY={4}>
        <Typography variant="h5" gutterBottom>
          Junior Members and Children
        </Typography>
        <List>
          {[
            '1. If you are 16-17 (inclusive) your parent or guardian must sign this agreement on your behalf. By signing this agreement your parent or guardian agrees to be responsible for your behaviour and actions at all times and to pay us any amounts that are due on your behalf.',
            '2. When you reach 16 your junior membership will end and you will automatically and immediately become a full adult/student member and sign a copy of this agreement.',
          ].map((text, index) => (
            <ListItem key={index}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box marginY={4}>
        <Typography variant="h5" gutterBottom>
          AGREEMENT, RELEASE OF LIABILITY & ASSUMPTION OF RISK
        </Typography>
        <Typography paragraph>
          {`In consideration for being permitted to utilise the facilities and to participate and engage in, Brazilian Jiu-Jitsu, Judo & Submission Grappling or all similar and related activities. I HEREBY AGREE AS FOLLOWS:`}
        </Typography>

        <Box marginY={4}>
          <Typography variant="h6" gutterBottom>
            PARTIES INCLUDED
          </Typography>
          <Typography paragraph>
            I understand that this Agreement, Release of Liability & Assumption
            of Risk includes and covers Ansar Jiu-Jitsu, or any associated
            entities, and any of its instructors, agents, officers, employees,
            students, and contractors, and additionally including but not
            limited to, anyone directly or indirectly involved in any manner in
            my Martial Arts Training, hereinafter collectively referred to in
            this Agreement, Release of Liability and Assumption of Risk as
            “Covered Parties.”
          </Typography>

          <Typography variant="h6" gutterBottom>
            DURATION OF RELEASE
          </Typography>
          <Typography paragraph>
            It is my understanding and intention that this Release and Agreement
            be effective not only for my first training session but for any
            subsequent training sessions or activities in any way associated
            with Ansar Jiu-Jitsu or any associated entities or any other Covered
            Parties.
          </Typography>

          <Typography variant="h6" gutterBottom>
            AGREEMENTS
          </Typography>
          <Typography paragraph>
            I understand it is agreed between the parties that Martial Arts
            Training is inherently dangerous and may result in injury or death.
            It is further agreed between the parties that the unforeseen may
            happen and that no one may delineate all risks or possibilities of
            error. Therefore, I specifically include in the Release, any injury
            resulting from any occurrence, whether foreseen or unforeseen, and
            whether contemplated or not contemplated, which is in any way
            connected with my Martial Arts Training or presence at the Ansar
            Jiu-Jitsu or any other place, venue, or entity connected with the
            Covered Parties.
          </Typography>

          <Typography variant="h6" gutterBottom>
            COVENANT NOT TO SUE
          </Typography>
          <Typography paragraph>
            I further agree that I WILL NOT SUE OR MAKE CLAIM against ANY of the
            Covered Parties for ANY damages or other losses or injuries
            sustained as a result of my Martial Arts Training even if caused by
            negligence or other fault of the Covered Parties.
          </Typography>

          <Typography variant="h6" gutterBottom>
            RELEASE OF LIABILITY
          </Typography>
          <Typography paragraph>
            I hereby release the Covered Parties, from any and all liability,
            claims, demands, actions, and causes of actions whatsoever arising
            out of or related to any loss, damage, or injury, up to and
            including DEATH, that may be sustained, even if caused by negligence
            or other fault of the Covered Parties while engaged in any Martial
            Arts Training or any and all related but unidentified activities.
          </Typography>

          <Typography variant="h6" gutterBottom>
            ASSUMPTION OF RISKS
          </Typography>
          <Typography paragraph>
            I realize and understand the scope, nature, and extent of the risks
            involved in Martial Arts Training, including the foreseen and the
            unforeseen, which shall include but not be limited to expose me to
            viruses and illness; broken bones; muscle and tendon injuries;
            restrictions on air and blood flow, which may result in disability
            and/or death.
          </Typography>

          <Typography variant="h6" gutterBottom>
            INDEMNIFICATION AND HOLD HARMLESS
          </Typography>
          <Typography paragraph>
            I also agree to indemnify, save, and hold the Covered Parties,
            harmless from all claims, judgments, costs, losses or proceedings of
            every kind and character specifically including, but not limited to,
            legal fees and expenses, and to reimburse the Covered Parties for
            any and all expenses whatsoever incurred in connection with any
            action brought as a result, either directly or indirectly, of my
            participation in Martial Arts Training, including but not limited
            to, actions brought on by myself, my estate, or on behalf of myself
            or my estate.
          </Typography>

          <Typography variant="h6" gutterBottom>
            PARTIES BOUND BY THIS AGREEMENT
          </Typography>
          <Typography paragraph>
            It is my understanding and intention that this Agreement, Release of
            Liability & Assumption of Risk be binding not only on myself, but on
            anyone or any entity, including but not limited to my estate and my
            heirs, that or who may be able to or do sue because of my injury or
            death. It is further my understanding and agreement that this
            Release is intended to and DOES in fact release the Covered Parties,
            from any and all claims or obligations, foreseen and unforeseen,
            contemplated and not contemplated, whatsoever arising in any way
            from my participation in Martial Arts Training, even if caused by
            the negligence or other fault of the Covered Parties.
          </Typography>

          <Typography variant="h6" gutterBottom>
            DUTY OF CARE
          </Typography>
          <Typography paragraph>
            I hereby agree to waive any and all duty of care, whether by
            omission or commission, or any other duty, which may be owed to me
            by the Covered Parties.
          </Typography>

          <Typography variant="h6" gutterBottom>
            ENFORCEABILITY
          </Typography>
          <Typography paragraph>
            I agree that if any portions of this Agreement, Release of Liability
            and Assumption of Risk are found to be unenforceable or against
            public policy or law, that only the portion found to be
            unenforceable shall fail and that all remaining portions shall
            remain in effect and be valid and enforceable, and I specifically
            waive any unenforceability or any public policy argument that I may
            make or that may be made on behalf of my estate or by anyone who
            would sue because of my injury or death.
          </Typography>

          <Typography variant="h6" gutterBottom>
            FAVOUR AGREEMENT
          </Typography>
          <Typography paragraph>
            It is my intention and my agreement that in all cases, this document
            be broadly construed in favour of the Covered Parties and against me
            and that any and all ambiguities or questions are resolved in favour
            of the Covered Parties.
          </Typography>

          <Typography variant="h6" gutterBottom>
            LEGAL RIGHTS
          </Typography>
          <Typography paragraph>
            It has been explained to me, and I understand, that by signing this
            document I am giving up important legal rights and it is my
            voluntary intention to do so. I also understand that, if I wish, I
            may to have my own lawyer review this document before I sign it.
            Upon its execution, this Agreement, Release of Liability &
            Assumption of Risk shall be binding against me in favour of the
            Covered Parties.
          </Typography>

          <Typography variant="h6" gutterBottom>
            DAMAGE AGREEMENT
          </Typography>
          <Typography paragraph>
            I hereby agree to be responsible and to pay for damages to any and
            all equipment, which is caused, in whole or in part, by my actions
            or the actions of any parties associated with me during my Martial
            Arts Training.
          </Typography>

          <Typography variant="h6" gutterBottom>
            RULES
          </Typography>
          <Typography paragraph>
            I understand that this is a Martial Arts Club with active and
            ongoing classes, and I will abide by any rules and regulations set
            forth pertaining to the Club. I understand that I am responsible for
            following these rules along with any other rules put in place by any
            of the Covered Parties and that I am also responsible for any injury
            or damage caused by the failure to follow these rules either by
            myself or by any parties associated with me. I understand that my
            attendance and/or performance at the academy may be photographed,
            filmed or taped and used for marketing and promotional purposes and
            I waive the right to compensation thereof.
          </Typography>

          <Typography variant="h6" gutterBottom>
            UNDERSTANDING OF AGREEMENT
          </Typography>
          <Typography paragraph>
            I hereby certify that I have read and understand the contents of
            this document “Agreement to participate in any class at Ansar
            Jiu-Jitsu and I wish to be bound by its terms and I understand that
            by signing this, I have forever given up important legal rights.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default TAC;
