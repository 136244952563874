import { Box, styled, Typography } from '@mui/material';

type BackgroundProps = {
  image?: string;
  colour?: string;
  large?: boolean;
};

export const Background = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'large',
})<BackgroundProps>(({ theme, image, colour, large }) => ({
  backgroundImage: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${image})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: colour,
  // backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: large ? 500 : 250, //625,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '14px',
})) as typeof Box;

export const Title = styled(Typography)(() => ({
  fontWeight: 'bold',
  textShadow: '#000000 1px 0 5px',
  textAlign: 'center',
  // letterSpacing: '.05rem',
}));

export const Subtitle = styled(Typography)(() => ({
  paddingBottom: '16px',
  textAlign: 'center',
}));
