import { Container } from '@mui/material';
import { FC, ReactElement } from 'react';
import Banner from '../../components/Banners/_Banner/Banner';
import GoogleForm from '../../components/GoogleForm/GoogleForm';
import TAC from '../../components/TAC/TAC';
import { Wrapper } from './RegistrationForm.styles';

const RegistrationForm: FC = (): ReactElement => {
  return (
    <>
      <Banner title="REGISTRATION FORM" />
      <Container maxWidth={'md'} disableGutters>
        <Wrapper>
          <GoogleForm />
          <TAC />
        </Wrapper>
      </Container>
    </>
  );
};

export default RegistrationForm;
