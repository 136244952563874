import { styled } from '@mui/material';

export const FormWrapper = styled('iframe')(({ theme }) => ({
  border: 0,
  height: '100vh',
  width: '100vh',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
