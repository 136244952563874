import { FC } from 'react';
import { FormWrapper } from './GoogleForm.styles';

const GoogleForm: FC = () => {
  const googleFormSrc = `https://docs.google.com/forms/d/e/${process.env.REACT_APP_GOOGLE_REGISTRATION_FORM}/viewform?embedded=true`;

  return <FormWrapper title="registration form" src={googleFormSrc} />;
};

export default GoogleForm;
