import { Box, Button, Grid, Typography, styled } from '@mui/material';

export const NavLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: '16px',
  width: '54px',

  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
})) as typeof Box;

export const NavTitle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  display: 'block',
  letterSpacing: '.2rem',
  fontWeight: 'bold',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
})) as typeof Typography;

export const GridContainer = styled(Grid)(() => ({
  justifyContent: 'space-between',
  padding: '8px 0',
}));

export const TitleWrapper = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const MenuIconWrapper = styled(Grid)(({ theme }) => ({
  flex: 1,

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const NavItemsWrapper = styled(Grid)(({ theme }) => ({
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    flex: 1,
  },
}));

export const PageLinksWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const PageLinks = styled(Button)(({ theme }) => ({
  color: '#ffff',
  textTransform: 'capitalize',
  fontSize: 16,

  ':hover, &.active': {
    backgroundColor: 'transparent',
    textDecorationLine: 'underline',
    textUnderlineOffset: '14px',
    textDecorationThickness: '2px',
    textDecorationColor: theme.palette.primary.main,
  },
})) as typeof Button;

export const ContactButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  fontSize: 16,
  height: '34px',
})) as typeof Button;

export const ContactWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
