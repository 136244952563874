import { FC, ReactElement } from 'react';
import Banner from '../../components/Banners/_Banner/Banner';

const NotFound: FC = (): ReactElement => {
  return (
    <>
      <Banner
        large
        title="404"
        subtitle="The page you are looking for does not exist"
        buttonText="Home"
        buttonLink="/"
      />
    </>
  );
};

export default NotFound;
